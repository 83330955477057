import { Footer, Header } from 'components';

export function NotFound() {
  return (
    <>
      <Header />
      <div>NotFound</div>
      <Footer />
    </>
  );
}
