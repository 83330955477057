import { Header, Footer, RegisterForm } from 'components';

export function Register() {
  return (
    <>
      <Header />
      <RegisterForm />
      <Footer />
    </>
  );
}
