import { Header, Footer, LoginForm } from 'components';

export function Login() {
  return (
    <>
      <Header />
      <LoginForm />
      <Footer />
    </>
  );
}
